import React, { useContext } from "react";
import { CartContext } from "../CartContext/CartContext";
import { Link,useNavigate } from "react-router-dom";
import './Cart.css'

const Cart = () => {
  const { cartItems, addToCart, removeOneFromCart, removeAllFromCart } = useContext(CartContext);
  const navigate = useNavigate();
  const handleIncreaseQuantity = (item) => {
    addToCart(item);
  };

  const handleDecreaseQuantity = (item) => {
    removeOneFromCart(item);
  };

  const handleRemoveAll = (item) => {
    removeAllFromCart(item);
  };
  if (cartItems.length === 0) {
    return (
      <div className="cart-warnings">
        <h1 className="cart-empty-text">Your cart is empty</h1>
        <Link to="/" className="button-empty">
          Continue Shopping
        </Link>
      </div>
    );
  }

  const cartForm= (e)=>{
    e.preventDefault();
      }

      const handleButtonClick = () => {

        navigate('/');
      };
    
  return (
    // <div className="cart">
    //   <h2>Cart</h2>
    //   {cartItems.length === 0 && <p>Your cart is empty</p>}
    //   {cartItems.map((item) => (
    //     <div key={item._id} className="cart-item">
    //       <img src={item.images && item.images.length > 0 ? item.images[0] : 'default-image.jpg'} alt={item.title} className="cart-item-image" />
    //       <div className="cart-item-info">
    //         <h3 className="cart-item-title">{item.title}</h3>
    //         <div className="cart-item-bottom">
    //           <p className="cart-item-price">${item.price}</p>
    //           <div className="cart-item-actions">
    //           <button onClick={() => handleDecreaseQuantity(item)} disabled={item.quantity === 1}>-</button>
    //             <span className="cart-item-quantity">{item.quantity}</span>
    //             <button onClick={() => handleIncreaseQuantity(item)}>+</button>
    //           </div>
    //         </div>
    //         <button onClick={() => handleRemoveAll(item)} className="cart-item-remove">Remove</button>
    //       </div>
    //     </div>
    //   ))}
    //   <p>Total: ${cartItems.reduce((acc, curr) => acc + curr.price * curr.quantity, 0)}</p>
    // </div>

    <main className="main-cart">
    <div className="whole-div-first-main">
      <div className="whole-div-cart page-width cart-items-upper">
        <div className="title-wrapper-with-link">
          <h1 className="title title-primary">Your Cart</h1>
          <Link to="/catalog" className="underlined-link">
            Continue shopping
          </Link>
        </div>
        <form className="cart-form" onSubmit={cartForm}>
          <div className="cart-items">
            <div className="main-content-js-cart">
              <table className="table-cart-items">
                <caption className="visually-hidden">Your cart</caption>
                <thead>
                  <tr>
                    <th
                      className="caption-with-letter-spacing"
                      colSpan={2}
                      scope="col"
                    >
                      Service
                    </th>
                    <th
                      className="medium-hide large-up-hide right caption-with-letter-spacing"
                      colSpan={1}
                      scope="col"
                    >
                      Total
                    </th>
                    <th
                      className="cart-items__heading--wide cart-items__heading--quantity small-hide caption-with-letter-spacing"
                      colSpan={1}
                      scope="col"
                    >
                      Quantity
                    </th>
                    <th
                      className="small-hide right caption-with-letter-spacing"
                      colSpan={1}
                      scope="col"
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item) => (
                    <tr className="cart-item" key={item._id}>
                      <td className="cart-item__media">
                        <div className="cart-item__image-container gradient global-media-settings">
                          <img
                            src={
                              item.images && item.images.length > 0
                                ? item.images[0]
                                : "default-image.jpg"
                            }
                            alt={item.title}
                            className="cart-item__image"
                          />
                        </div>
                      </td>
                      <td className="cart-item__details">
                        <Link to="/" className="cart-item__name h4 break">
                          {item.title}
                        </Link>
                        <div className="product-option">${item.price}</div>
                      </td>

                      <td className="cart-item__totals right medium-hide large-up-hide">
                        <div className="loading-overlay hidden"></div>
                        <div className="cart-item__price-wrapper">
                        <span className="price price--end">
                          ${item.price * item.quantity}
                        </span>
                      </div>
                      </td>

                      <td className="cart-item__quantity">
                        <div className="quantity-popover">
                          <div className="cart-item__quantity-wrapper quantity-popover-wrapper">
                            <label className="visually-hidden">
                              Quantity
                            </label>
                            <div className="quantity-popover-container">
                              <div className="quantity cart-quantity">
                                <button
                                  onClick={() => handleDecreaseQuantity(item)}
                                  disabled={item.quantity === 1}
                                  className={`quantity__button ${
                                    item.quantity === 1 ? "disabled" : ""
                                  }`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    class="icon icon-minus"
                                    fill="none"
                                    viewBox="0 0 10 2"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </button>

                                <input
                                  className="quantity__input"
                                  readOnly
                                  value={item.quantity}
                                />
                                <button
                                  className="quantity__button"
                                  onClick={() => handleIncreaseQuantity(item)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    class="icon icon-plus"
                                    fill="none"
                                    viewBox="0 0 10 10"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </button>
                              </div>
                            </div>

                            <div className="cart-remove-button">
                              <button
                                onClick={() => handleRemoveAll(item)}
                                className=" delete remove-button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 16 16"
                                  aria-hidden="true"
                                  focusable="false"
                                  className="icon icon-remove"
                                >
                                  <path
                                    d="M14 3h-3.53a3.07 3.07 0 00-.6-1.65C9.44.82 8.8.5 8 .5s-1.44.32-1.87.85A3.06 3.06 0 005.53 3H2a.5.5 0 000 1h1.25v10c0 .28.22.5.5.5h8.5a.5.5 0 00.5-.5V4H14a.5.5 0 000-1zM6.91 1.98c.23-.29.58-.48 1.09-.48s.85.19 1.09.48c.2.24.3.6.36 1.02h-2.9c.05-.42.17-.78.36-1.02zm4.84 11.52h-7.5V4h7.5v9.5z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M6.55 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5zM9.45 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="cart-item__totals right small-hide">
                        <div className="cart-item__price-wrapper">
                          <span className="price price--end">
                            ${item.price * item.quantity}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div className="cart-footer-wrapper">

        <div>
          <div className="cart-footer cart-padding">
            <div className="cart-blocks">
              <div className="cart-end-part">
                <div className="cart-footer-totals">
                  <h2 className="totals-total">Estimated total</h2>
                  <p className="totals-total-value">
                    {" "}
                    ${cartItems
                      .reduce(
                        (acc, curr) => acc + curr.price * curr.quantity,
                        0
                      )
                      .toFixed(2)}
                  </p>
                </div>
                <div className="button-ending-cart">
                <button type="button" className="button checkout-button" onClick={handleButtonClick}>
                  Proceed to checkout
                </button>
              </div>
              </div>
 
            </div>
          </div>
        </div>
      </div>
  </main>
  );
};

export default Cart;
