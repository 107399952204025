import React, { useState, useEffect,useContext,useRef,useLayoutEffect} from "react";
import { CartContext } from "../CartContext/CartContext";
import "./Header.css";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faShoppingCart,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const Header = ({ customStyles,navigation={} }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAnnouncementScrolled, setAnnouncementScrolled] = useState(false);
  const [cartChange, setCartChange] = useState(false);
  const { websiteName } = useParams();
  const { cartItems } = useContext(CartContext);
  const [logoHeight, setLogoHeight] = useState(0);
  const logoRef = useRef(null);

  useEffect(() => {
    setCartChange(true);
    const timer = setTimeout(() => setCartChange(false), 500);
    return () => clearTimeout(timer);
  }, [cartItems]);
  useEffect(() => {
    if (logoRef.current) {
      setLogoHeight(logoRef.current.offsetHeight);
      // console.log(logoRef.current.offsetHeight, '🪰')
    }
  }, []);
  useEffect(() => {
    function handleLoad() {
      setLogoHeight(logoRef.current.offsetHeight);
    }

    const logoElement = logoRef.current;

    if (logoElement) {
      if (logoElement.complete) {
        handleLoad();
      } else {
        logoElement.addEventListener('load', handleLoad);
      }

      return () => {
        logoElement.removeEventListener('load', handleLoad);
      };
    }
  }, [customStyles]); 
  
  const toggleMenu = () => {
    const menu = !menuOpen
      ? document.querySelector(".header-menu-closed")
      : document.querySelector(".header-menu-open");

    if (!menuOpen) {
      menu.style.animation = "slideIn 0.3s forwards";
    } else {
      menu.style.animation = "slideOut 0.3s forwards";
    }

    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleAnnouncementScroll = () => {
    if (window.scrollY > 28) {
      setAnnouncementScrolled(true);
    } else {
      setAnnouncementScrolled(false);
    }
  };
  const { header = [] } = navigation;
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleAnnouncementScroll);
    return () => {
      window.removeEventListener("scroll", handleAnnouncementScroll);
    };
  }, []);

  const {
    announcementBarBgColor,
    announcementBarTextColor,
    announcementBarText,
    navBarBgColor,
    navBarLogoFontSize,
    navBarIconLinkColor,
    navBarMenuLinkColor,
    brandName,
    logoUrl,
    logoImageSize,
  } = customStyles || {};


  // console.log(navigation);
  return (
    <>
      <div
        className="announcement-bar"
        style={{ backgroundColor: announcementBarBgColor }}
      >
        <p style={{ color: announcementBarTextColor }}>
          {announcementBarText
            ? announcementBarText
            : "20% Off On Example Store!"}
        </p>
      </div>
      <div className="header-divider"></div>
      <header
        className={`header${isScrolled ? " header-shadow" : ""}`}
        style={{ backgroundColor: navBarBgColor }}
      >
        <nav className="header-nav">
          <div className="header-nav-container">
            <div className="hamburger-menu" onClick={toggleMenu}>
              <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
            </div>
            <div className="header-logo">
              {logoUrl ? (
                <Link
                  to="/"
                  className="header-logo-link"
                  style={{ fontSize: navBarLogoFontSize }}
                >
                  <img
                    src={logoUrl}
                    alt="Logo"
                    ref={logoRef}
                    style={{ width: logoImageSize, height: "auto" }}
                  />
                </Link>
              ) : (
                <Link
                  to="/"
                  className="header-logo-link"
                  style={{ fontSize: navBarLogoFontSize }}
                >
                  {brandName ? brandName : "Example"}
                </Link>
              )}
            </div>
            <div className="header-nav-content">
              <ul
                className={`header-menu-${menuOpen ? "open" : "closed"}`}
                style={{ top: isAnnouncementScrolled ? `${logoHeight+20+4}px` : `${logoHeight+29+20+14}px` }}
              >
                {header.map((navItem) => (
                  <li className="header-menu-item" key={navItem._id}>
                    <Link
                      to={navItem.link}
                      className="header-menu-link"
                      style={{ color: navBarMenuLinkColor }}
                    >
                      {navItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <ul
                className={`header-menu-desktop`}
                style={{ top: isAnnouncementScrolled ? "45px" : "80px" }}
              >
                {header.map((navItem) => (
                  <li className="header-menu-item" key={navItem._id}>
                    <Link
                      to={navItem.link}
                      className="header-menu-link"
                      style={{ color: navBarMenuLinkColor }}
                    >
                      {navItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="header-icons">
             

                <Link to={`/cart`} className="header-icon-link cart-icon-link">
                  <div className="cart-icon-container">
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      className="material-icons"
                    />
                    <span className="cart-items-count">
                      {cartItems.reduce((acc, item) => acc + item.quantity, 0)}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;