import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

function Index() {
  const [customStyles, setCustomStyles] = useState({});
  const [sliderImages, setSliderImages] = useState([]);
  const [productPageStyles, setProductPageStyles] = useState({});
  const [footerStyles, setFooterStyles] = useState({});
  const [navigation, setNavigation] = useState({});
// Add websiteId state
const [websiteId, setWebsiteId] = useState("");

  useEffect(() => {
    function handleReceiveMessage(event) {
      console.log(event.data);
      const {
        options,
        sliderImages,
        productPageStyles,
        navigation,
        footerStyles,
        websiteId //
      } = event.data;
      // console.log(navigation);
      setWebsiteId(websiteId); 
      setCustomStyles(options);
      setSliderImages(sliderImages);
      setProductPageStyles(productPageStyles);
      setFooterStyles(footerStyles);

      if (navigation) {
        setNavigation(navigation);
      }
    }

    window.addEventListener("message", handleReceiveMessage, false);

    return () => {
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, []);

  return (
    <React.StrictMode>
      <App
        customStyles={customStyles}
        sliderImages={sliderImages}
        productPageStyles={productPageStyles}
        navigation={navigation}
        websiteId={websiteId} 
      />
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);

reportWebVitals();
