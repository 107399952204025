import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import "./AboutUs.css";
import { useState,useEffect } from "react";
import axios from "axios";
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

const AboutUs = () => {
  const [location, setLocation] = useState(null);
  const containerStyles = {
    height: "50vh",
    width: "100%",
  };

  const mapStyles = [
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [{ color: "#d3d3d3" }],
    },
    {
      featureType: "transit",
      elementType: "geometry.fill",
      stylers: [{ color: "#808080" }],
    },
  ];

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/calendar/getStoreLocationforTemplate`, {
            withCredentials:true
          }
        );

        setLocation({
          lat: parseFloat(response.data.storeLocation.latitude),
          lng: parseFloat(response.data.storeLocation.longitude),
        });
      } catch (error) {
        console.error('Error fetching location:', error.message);
      }
    };

    fetchLocation();
  },[]);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }
  return (
    <div className="about-us-container">
      <h1>About Us</h1>
      <div className="about-us-content">
        <p>
          Your business started from a single idea. From there, you nurtured it
          into a living, breathing entity that creates value, makes a
          difference, and probably employs others.
        </p>
        <p>
          We believe in the transformative power of small businesses, in what
          you do, and in your ability to make a difference.
        </p>
      </div>
      {isLoaded && location && (
        <GoogleMap
        mapContainerStyle={containerStyles}
        zoom={13}
        center={location}
        options={{ styles: mapStyles }}
      >
      <Marker
      position={location}
      onClick={() => alert("You clicked the custom marker!")}
    />
  </GoogleMap>
      )}
    </div>
  );
};

export default AboutUs;
