
import React, { useEffect, useState,useContext } from "react";
import "./FeaturedServices.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { CartContext } from "../CartContext/CartContext";
const FeaturedProducts = () => {
  const { cartItems, addToCart, removeOneFromCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [categoryServicesMap, setCategoryServicesMap] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/services`, {
        withCredentials: true,
      })
      .then((response) => {
        const services = response.data.data.services;
        // console.log(services);
        let noCategoryServices = [];
        const map = services.reduce((acc, curr) => {
          if (curr.category.length === 0) {
            noCategoryServices.push(curr);
            return acc;
          }
          curr.category.forEach((cat) => {
            if (!acc[cat]) {
              acc[cat] = [curr];
            } else {
              acc[cat].push(curr);
            }
          });
          return acc;
        }, {});
        // if no categories exist, create a new category "No Category"
        if (noCategoryServices.length > 0) {
          map["Explore Services"] = noCategoryServices;
        }
        setCategoryServicesMap(map);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleServiceClick = (service) => {
    navigate(`/service/${service._id}`);
  };
  const handleAddToCart = (service) => {
    addToCart(service);
  };



  const handleIncreaseQuantity = (id) => {
    const item = cartItems.find(item => item._id === id);
    if (item) {
      addToCart(item);
    }
  };

  const handleDecreaseQuantity = (id) => {
    const item = cartItems.find(item => item._id === id);
    if (item) {
      removeOneFromCart(item);
    }
  };

  return (
    <div className="featured-products">
    {Object.keys(categoryServicesMap).includes('Explore Services') ? null : <h1 className="main-title">All Services</h1>}
    {Object.keys(categoryServicesMap).map((category, index) => (
      <div key={category} className={`category-section ${index !== 0 ? 'divider' : ''}`}>
        <div className="category-header">
          <h2 className="featured-title">{category}</h2>
          <a href='/catalog' className="see-all-button">See All</a>
        </div>
        <div className="services-container">
          {categoryServicesMap[category].map((service, index) => (
              <div key={service._id} className="service-card">
                <Carousel
                  showStatus={false}
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={false}
                  onClickItem={() => handleServiceClick(service)}
                  emulateTouch={true}
                  dynamicHeight={true}
                  swipeable={true}
                >
                  {service.images.map((image, idx) => (
                    <div key={idx}>
                      <img
                        src={image}
                        alt={`Service ${idx + 1}`}
                        className="service-image"
                      />
                    </div>
                  ))}
                </Carousel>
                <a
                  href={`/service/${service._id}`}
                  className="service-name-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleServiceClick(service);
                  }}
                >
                  <h3 className="service-name">{service.title}</h3>
                </a>
                <div className="service-card-footer">
                <div className="price-and-add-to-cart-container">
                <p className="service-price">${service.price}</p>
                {cartItems.find(item => item._id === service._id) ? (
                  <div className="quantity-container-all-services">
                    <button
                      className="quantity-button-all-services"
                      onClick={() => handleDecreaseQuantity(service._id)}
                    >
                      -
                    </button>
                    <span className="quantity-find">
                      {cartItems.find(item => item._id === service._id).quantity}
                    </span>
                    <button
                      className="quantity-button-all-services"
                      onClick={() => handleIncreaseQuantity(service._id)}
                    >
                      +
                    </button>
                  </div>
                ) : (
                  <button
                    className="add-to-cart-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCart(service);
                    }}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ))}
</div>
  );
};

export default FeaturedProducts;
