
import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

const Footer = ({ footerStyles, navigation={} }) => {
  const {
    footerBgColor = '#333', // Default background color
    footerBrandName = 'Default Brand',
    copyrightText = '© 2023 Default Company. All Rights Reserved.',
    facebookLink,
    twitterLink,
    instagramLink,
    tiktokLink
  } = footerStyles || {};

  return (
    <footer className="footer" style={{ backgroundColor: footerBgColor }}>
      <div className="footer-container">
        <div className="footer-logo">{footerBrandName}</div>
        <nav className="footer-nav">
          <ul className="footer-menu">
            {navigation?.footer?.map((item, index) => (
              <li key={index} className="footer-menu-item">
                <a href={item.url} className="footer-menu-link">{item.label}</a>
              </li>
            ))}
          </ul>
        </nav>
        <div className="footer-social-links">
          {facebookLink && (
            <a href={facebookLink} target="_blank" rel="noopener noreferrer" className="social-link">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          )}
          {twitterLink && (
            <a href={twitterLink} target="_blank" rel="noopener noreferrer" className="social-link">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
          )}
          {instagramLink && (
            <a href={instagramLink} target="_blank" rel="noopener noreferrer" className="social-link">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          )}
          {tiktokLink && (
            <a href={tiktokLink} target="_blank" rel="noopener noreferrer" className="social-link">
              <FontAwesomeIcon icon={faTiktok} />
            </a>
          )}
        </div>
        <div className="footer-copyright">{copyrightText}</div>
      </div>
    </footer>
  );
};

export default Footer;
