import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./ImageSlider.css";

const ImageSlider = ({
  sliderImages,
  captionText,
  buttonText,
  imageSlidePosition1,
  imageSlidePosition2,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);



  const slides = useMemo(
    () =>
      (sliderImages || []).map((imageUrl) => ({
        image: imageUrl,
      })),
    [sliderImages]
  );

  for (let i = 0; i < slides.length; i++) {
    if (slides[i].image === "")
      slides[i].image = "https://i.ibb.co/WPPbZPL/1.png";
  }

  if (slides.length === 0) {
    slides[0] = "https://i.ibb.co/WPPbZPL/1.png";
    slides[1] = "https://i.ibb.co/WPPbZPL/1.png";
  }

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  }, [slides]);

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      nextSlide();
    }, 10000); // 10 seconds

    return () => {
      clearInterval(slideInterval);
    };
  }, [nextSlide]);

  return (
    <div className="slider-container">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slider-image-wrapper ${
            index === currentSlide ? "active" : ""
          }`}
          style={{
            backgroundImage: `url(${slide.image ? slide.image : slide})`,
            backgroundPosition: `${
              index + 1 === 1
                ? `${imageSlidePosition1}%`
                : `${imageSlidePosition2}%`
            }`,
          }}
        ></div>
      ))}
      <div className="slider-caption">
        <h2>{captionText ? captionText : "This text can be changed 💬"}</h2>
        <button className="slider-shop-all">
          {buttonText ? buttonText : "I am a button!"}
        </button>
      </div>
      <button className="slider-prev" onClick={prevSlide}>
        &#10094;
      </button>
      <button className="slider-next" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
};

export default ImageSlider;
