import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Catalog.css";
import { useNavigate } from "react-router-dom";
const Catalog = () => {
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sortCategory, setSortCategory] = useState('All');
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/services`, { withCredentials: true })
      .then((response) => {
        setServices(response.data.data.services);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/services/categories`, { withCredentials: true })
      .then((response) => {
        setCategories(response.data.data.categories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSortCategoryChange = (e) => {
    setSortCategory(e.target.value);
  }

  const sortedServices = sortCategory === 'All' ? services : services.filter(service => service.category.includes(sortCategory));
  const handleServiceClick = (service) => {
    navigate(`/service/${service._id}`);
  };
  return (
    <div className="main-catalog">
    
    <div className="catalog">
      <h1 className="main-title-catalog">Catalog</h1>
      <div className="sort-container">
        <label htmlFor="sort-category" className="sort-label">Sort by Category:</label>
        <select id="sort-category" value={sortCategory} onChange={handleSortCategoryChange}>
          <option value="All">All</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>{category}</option>
          ))}
        </select>
      </div>
      <div className="services-container-catalog">
        {sortedServices.map((service, index) => (
          <div key={service._id} className="service-card-catalog"    onClick={() => handleServiceClick(service)} >
            <img src={service.images[0]} alt={service.title} className="service-image-catalog"/>
            <h3 className="service-name-catalog">{service.title}</h3>
            <p className="service-price-catalog">${service.price}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
 
};

export default Catalog;
