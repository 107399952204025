import React, { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  
  const addToCart = (item) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find((i) => i._id === item._id);
      if (existingItem) {
        return prevItems.map((i) =>
          i._id === item._id ? { ...i, quantity: i.quantity + 1 } : i
        );
      } else {
        return [...prevItems, { ...item, quantity: item.quantity ? item.quantity : 1 }];
      }
    });
  };
  
  
  
  const removeOneFromCart = (item) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find((i) => i._id === item._id);
      if (existingItem && existingItem.quantity > 1) {
        return prevItems.map((i) =>
          i._id === item._id ? { ...i, quantity: i.quantity - 1 } : i
        );
      } else {
        return prevItems.filter((i) => i._id !== item._id);
      }
    });
  };

  const removeAllFromCart = (item) => {
    setCartItems([]);
    localStorage.setItem("cartItems", JSON.stringify([]));
  };

  return (
    <CartContext.Provider
      value={{ cartItems, addToCart, removeOneFromCart, removeAllFromCart }}
    >
      {children}
    </CartContext.Provider>
  );
};
