import React, { useState, useEffect, useCallback,useContext} from "react";
import parse from "html-react-parser";
import "./ServicePage.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { CartContext } from "../CartContext/CartContext";
const ProductPage = ({ styles }) => {
  const {
    addToCartButtonColor,
    addToCartButtonBgColor,
    bookNowButtonColor,
    bookNowButtonBgColor,
  } = styles || {};

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  const [quantity, setQuantity] = useState(1);
  const [service, setService] = useState(null);
  const { serviceId } = useParams();
  const [similarServices, setSimilarServices] = useState([]);
  const [serviceImages, setServiceImages] = useState([]);
  const [similarServiceImages, setSimilarServiceImages] = useState([]);
  const { addToCart, removeOneFromCart, removeAllFromCart } = useContext(CartContext);
  
  useEffect(() => {
    if (service && service.images) {
      const images = service.images.map((image) => ({
        original: image,
        thumbnail: image,
      }));
      setServiceImages(images);
    }
  }, [service]);

  // const handleAddToCart = () => {
  //   if (service) {
  //     addToCart({ ...service, quantity });
  //     setQuantity(1); // reset the quantity after adding to the cart
  //   }
  // };
  
  const handleAddToCart = () => {
    if (service) {
      // iterate quantity times and call addToCart for each iteration
      for (let i = 0; i < quantity; i++) {
        addToCart(service);
      }
      setQuantity(1); // reset the quantity after adding to the cart
    }
  };
  
  
  const displaySimilarServices = useCallback(() => {
    if (service && service.category && service.category.length > 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/v1/services`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          const fetchedServices = response.data.data.services;
          const filteredServices = fetchedServices.filter(
            (s) => s._id !== service._id && s.category.some(r=> service.category.includes(r))
          );
  
          const similarImages = filteredServices.map((similarService) => ({
            original: similarService.images[0],
            thumbnail: similarService.images[0],
          }));
  
          setSimilarServiceImages(similarImages);
          setSimilarServices(filteredServices);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [service]);
  

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/services/${serviceId}`, {
        withCredentials: true,
      })
      .then((response) => {
        setService(response.data.data.service);
        displaySimilarServices();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [serviceId, displaySimilarServices]);

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + change;
      return newQuantity < 1 ? 1 : newQuantity;
    });
  };
  

  if (!service) {
    return <div>Loading...</div>;
  }

  return (
    <div className="product-page">
      <div className="product-details-container">
        <div className="product-image-container">
        {serviceImages.length > 0 && (
          <ImageGallery
            items={serviceImages}
            showFullscreenButton={false}
            showPlayButton={false}
          />
        )}
        </div>
        <div className="product-info">
          <h1 className="product-title">{service.title}</h1>
          <p className="product-price">${service.price}</p>
          <p>Quantity</p>
          <div className="quantity-container-service-page">
        
          <button
            className="quantity-button-service-page"
            onClick={() => handleQuantityChange(-1)}
          >
            &ndash;
          </button>
          <span className="quantity-number-service-page">{quantity}</span>
          <button
            className="quantity-button-service-page"
            onClick={() => handleQuantityChange(1)}
          >
            &#43;
          </button>
        </div>
          <div className="product-actions">
            <button
              className="add-to-cart"
              onClick={handleAddToCart}
              style={{
                color: addToCartButtonColor,
                backgroundColor: addToCartButtonBgColor,
              }}
            >
              Add to Cart
            </button>

            <button
              className="buy-now"
              style={{
                color: bookNowButtonColor,
                backgroundColor: bookNowButtonBgColor,
              }}
            >
              Book Now
            </button>
          </div>
          <div className="product-details">
            <h2>Product Details</h2>
            {parse(decodeHtml(service.description))}
          </div>
        </div>
      </div>

      {similarServices.length > 0 && (
        <div className="similar-products-container">
          <h2>Similar Services</h2>
          <div className="similar-products-grid">
            {similarServices.map((similarService) => (
              <div key={similarService._id} className="similar-product">
                <img src={similarService.images && similarService.images[0]} alt={similarService.title} />
                <div className="similar-product-content">
                  <p>{similarService.title}</p>
                  <p>${similarService.price}</p>
                  <button
                    style={{
                      color: addToCartButtonColor,
                      backgroundColor: addToCartButtonBgColor,
                    }}
                    onClick={() => addToCart({ ...similarService, quantity })}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
};

export default ProductPage;