import "./SiteWideChatBox.css";
import { useState, useEffect, useRef, useContext } from "react";
import userPic from "../../assets/new-loader-blue.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import ChatBoxInput from "../ChatBoxInput/ChatBoxInput";
import { CartContext } from "../CartContext/CartContext";
import {
  faChevronDown,
  faCalendarCheck,
  faClock,
  faPaperPlane,
  faFilePdf,
  faQuestionCircle,
  faChevronUp,
  faPaperclip,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFile,
  faDownload,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import parse from "html-react-parser";
const SiteWideChatBox = ({ websiteId }) => {
  const {
    cartItems,
    addToCart,
    removeOneFromCart,
    removeAllFromCart,
    clearCart,
  } = useContext(CartContext);

  const [isChatOpen, setIsChatOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [showAllAttachments, setShowAllAttachments] = useState(false);
  const [showChatIntro, setShowChatIntro] = useState(true);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const [loopCount, setLoopCount] = useState(0);
  const [executeStripePayment, setExecuteStripePayment] = useState(false);
  const [scrollbarHeight, setScrollbarHeight] = useState(0); // Controls the height of the scrollbar
  const maxLoops = 5; // Set the maximum number of loops here
  const [triggerUserMessage, setTriggerUserMessage] = useState(false);
  const [introColor, setIntroColor] = useState("#4b4ff2");
  // State for chatbox color
  const [chatboxColor, setChatboxColor] = useState("#4b4ff2");
  // State for logo URL
  const [logoUrl, setLogoUrl] = useState(
    "https://i.ibb.co/4WssscG/logo-full.png"
  );
  // State for logo size
  const [logoSize, setLogoSize] = useState("100px");
  // State for input color
  const [inputColor, setInputColor] = useState("#FFFFFF");
  const [gradientColors, setGradientColors] = useState(["#4b4ff2", "#5ca2ef"]); // Default gradient colors
  const [chatBubbleStyle, setChatBubbleStyle] = useState("circular");
  const [textBoxContent, setTextBoxContent] = useState("Book Our Services");
  const [servicesData, setServicesData] = useState(null);
  const [threadId, setThreadId] = useState(() =>
    localStorage.getItem("chatbotThreadId")
  );
  const [currentMessage, setCurrentMessage] = useState("");
  const [confirmSelectedSlot, setConfirmSelectedSlot] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const chatHistoryRef = useRef(null);
  const [assistantId, setAssistantId] = useState(null);
  const chatScrollerRef = useRef(null);
  const [chatHistory, setChatHistory] = useState([
    {
      role: "assistant",
      content: "Hello! How may I help you?",
      userpic: userPic,
    },
  ]);

  const [isMessageEmpty, setIsMessageEmpty] = useState(true);
  const [textBoxContentWidth, setTextBoxContentWidth] = useState(200);

  useEffect(() => {
    const fetchChatbotInfo = async () => {
      try {
        if (websiteId) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/v1/chatbotRoutes/getChatbotInfoIframe?websiteId=${websiteId}`,
            {
              withCredentials: true,
            }
          );

          const {
            introColor,
            chatboxColor,
            brandLogo,
            logoSize,
            inputColor,
            gradientColors,
            bubbleType,
            rectangularBubbleText,
          } = response.data;

          setIntroColor(introColor);
          setChatboxColor(chatboxColor);
          setLogoUrl(brandLogo);
          setLogoSize(logoSize); // Update this line
          setInputColor(inputColor);
          setGradientColors(gradientColors); // Update gradient colors
          setChatBubbleStyle(bubbleType);
          setTextBoxContent(rectangularBubbleText);
        }
      } catch (error) {
        console.error("Error fetching chatbot information:", error);
      }
    };

    fetchChatbotInfo();
  }, [websiteId]);

  useEffect(() => {
    const handleMessage = (event) => {
      // Optional: Verify the event's origin for security purposes
      // if (event.origin !== 'Expected origin') return;
      if (
        event.origin !== "http://localhost:3000" &&
        event.origin !== "https://bricksoft.ai"
      )
        return;

      if (event.data.type === "GRADIENT_COLOR_CHANGE") {
        const gradientCss = event.data.gradientCss;
        const colorMatch = gradientCss.match(
          /linear-gradient\(to right, (#[0-9a-fA-F]{6}), (#[0-9a-fA-F]{6})\)/
        );
        if (colorMatch) {
          setGradientColors([colorMatch[1], colorMatch[2]]);
        }
      }

      switch (event.data.type) {
        case "GRADIENT_COLOR_CHANGE":
          // Extracts the gradient CSS from the message and updates state
          const gradientCss = event.data.gradientCss;
          const colorMatch = gradientCss.match(
            /linear-gradient\(to right, (#[0-9a-fA-F]{6}), (#[0-9a-fA-F]{6})\)/
          );
          if (colorMatch) {
            setGradientColors([colorMatch[1], colorMatch[2]]);
            setIsChatOpen(false);
          }
          break;

        case "COLOR_CHANGE":
          setIntroColor(event.data.color); // Updates intro color
          setIsChatOpen(true);
          break;
        case "CHAT_BUBBLE_STYLE":
          setIsChatOpen(false);
          setChatBubbleStyle(event.data.style);
          break;
        case "CHAT_BUBBLE_TEXT":
          setTextBoxContent(event.data.text);

        case "CHAT_BUBBLE_WIDTH":
          setTextBoxContentWidth(event.data.width);

        case "CHATBOX_COLOR_CHANGE":
          setChatboxColor(event.data.chatboxColor); // Updates chatbox color
          break;
        case "LOGO_CHANGE":
          setLogoUrl(event.data.logoUrl); // Updates logo URL
          setIsChatOpen(true);
          break;
        case "LOGO_SIZE_CHANGE":
          setLogoSize(`${event.data.logoSize}px`); // Updates logo size
          setIsChatOpen(true);
          break;
        case "INPUT_COLOR_CHANGE":
          setInputColor(event.data.inputColor); // Updates input color
          break;
        case "UPDATE_STYLES":
          if (event.data.color) {
            setIntroColor(event.data.color);
          }
          if (event.data.chatboxColor) {
            setChatboxColor(event.data.chatboxColor);
          }
          // Only update logoUrl if a new value is provided
          if (event.data.logoUrl) {
            setLogoUrl(event.data.logoUrl);
          } else {
            // Fallback or maintain current state
            // This line is crucial to avoid resetting to the default logo
          }
          // Only update logoSize if a new value is provided
          if (event.data.logoSize) {
            setLogoSize(`${event.data.logoSize}px`);
          }
          break;

        default:
          // Handle any unknown message type or ignore
          console.log("Received unknown message type:", event.data.type);
          break;
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const chatSales = false;

  useEffect(() => {
    if (triggerUserMessage) {
      handleUserMessage();
      setTriggerUserMessage(false); // Reset trigger
    }
  }, [triggerUserMessage]);

  // Define ref at the top of your component
  const handleStartChat = () => {
    setShowChatIntro(false);
  };

  const handleVideoEnd = () => {
    if (loopCount < maxLoops - 1) {
      // If we haven't reached the max number of loops
      setLoopCount(loopCount + 1); // Increment the loop count
      document.querySelector(".userpic__video")?.play(); // Restart the video
    } else {
      // If it's the last loop, do nothing, and the video will stop on the last frame
      console.log("Final loop reached, video will stop on the last frame.");
    }
  };

  const isImage = (fileName) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
    const extension = fileName.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  };

  const getFileTypeIcon = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return faFilePdf;
      case "doc":
      case "docx":
        return faFileWord;
      case "xls":
      case "xlsx":
        return faFileExcel;
      case "mp3":
      case "wav":
        return faFileAudio;
      case "mp4":
      case "avi":
        return faFileVideo;
      default:
        return faFile;
    }
  };

  const formatFileSize = (sizeInBytes) => {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;
    const sizeInGB = sizeInMB / 1024;

    if (sizeInBytes < 1024) {
      return `${sizeInBytes} Bytes`;
    } else if (sizeInKB < 1024) {
      return `${sizeInKB.toFixed(1)} KB`;
    } else if (sizeInMB < 1024) {
      return `${sizeInMB.toFixed(1)} MB`;
    } else {
      return `${sizeInGB.toFixed(1)} GB`;
    }
  };

  const confettiConfig = {
    angle: 90,
    spread: 1000,
    startVelocity: 30,
    elementCount: 100,
    decay: 0.9,
  };



  // Assuming cartItems is an array and has at least one item
  const fetchQuestions = async (cartItemId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/questions/getQuestions/${cartItemId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Include other headers as required, such as Authorization for JWT
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching questions:", error);
      throw error; // Or handle error as needed
    }
  };

  function determineBorderRadius(isChatOpen, chatBubbleStyle) {
    if (!isChatOpen) {
      // When the chatbox is closed
      if (chatBubbleStyle === "circular") {
        return "50%";
      } else if (chatBubbleStyle === "rectangular") {
        return "15px";
      }
    }
    return "0"; // When the chatbox is open
  }
  function determinePillWidth(isChatOpen, chatBubbleStyle) {
    if (!isChatOpen) {
      // When the chatbox is closed
      if (chatBubbleStyle === "circular") {
        return "60px";
      } else if (chatBubbleStyle === "rectangular") {

        return `${textBoxContentWidth+30}px`;
      }
    }
    return "100%"; // When the chatbox is open
  }

  function determinePillHeight(isChatOpen, chatBubbleStyle) {
    if (!isChatOpen) {
      // When the chatbox is closed
      if (chatBubbleStyle === "circular") {
        return "60px";
      } else if (chatBubbleStyle === "rectangular") {
        return "40px";
      }
    }
    return "100%"; // When the chatbox is open
  }

  const filterQuestionsByAskBeforeOrder = (questions) => {
    return questions.filter((question) => question.askBeforeOrder);
  };

  const scrollToBottom = () => {
    const scroller = chatScrollerRef.current;
    scroller.scrollTop = scroller.scrollHeight;
  };

  useEffect(() => {
    if (confirmSelectedSlot) {
      const confirmationMessage = {
        role: "assistant",
        content: "Now, let's get your email address to confirm the booking 😃",
        assistantId: assistantId, // assuming assistantId is available in the scope
        userpic: userPic,
        show: "showEmailConfirmationField",
      };

      setChatHistory((prev) => [...prev, confirmationMessage]);
    }
  }, [confirmSelectedSlot]);

  useEffect(() => {
    const initChat = async () => {
      if (!threadId && assistantId) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/v1/openAIRoutes/startChat`,
            { assistantId }
          );
          const newThreadId = response.data.id;
          localStorage.setItem("chatbotThreadId", newThreadId);
          setThreadId(newThreadId);
        } catch (error) {
          console.error("Failed to initialize chat:", error);
        }
      }
    };

    initChat();
  }, [assistantId]);

  // Handle change in the textarea
  const handleMessageChange = (event) => {
    const messageText = event.target.value;
    setCurrentMessage(messageText);
    setIsMessageEmpty(!messageText.trim()); // Update isMessageEmpty based on whether messageText is empty or not
  };

  useEffect(() => {
    console.log(chatHistory); // Log the updated chat history
  }, [chatHistory]); // This effect runs every time `chatHistory` changes.
  const handleUserMessage = async (pillMessage) => {
    // Check if there's no message or it's not one of the predefined pill messages
    if (!currentMessage.trim() && !pillMessage) return;

    // Define a static response for specific questions
    const staticResponse =
      "Hi, this is for customizing your chatbot. To use this chatbot, add the code to your site above.";

    // If there's a "pillMessage", handle it directly
    const messageToProcess = currentMessage.trim()
      ? currentMessage
      : pillMessage;

    if (
      [
        "What services do you offer?",
        "Where are you located?",
        "I need help with other things",
        "What is the purpose of all this?",
      ].includes(messageToProcess)
    ) {
      // Handle predefined questions
      setChatHistory((prev) => [
        ...prev,
        { role: "user", content: messageToProcess },
        { role: "assistant", content: staticResponse, userpic: userPic },
      ]);
      setCurrentMessage(""); // Clear the input field
      setIsBotTyping(false);
    } else {
      // Handle other messages
      setChatHistory((prev) => [
        ...prev,
        { role: "user", content: messageToProcess },
      ]);
    }

    // UI updates as needed
  };

  // Remember to call this function at the appropriate time in your application flow

  useEffect(() => {
    const scroller = chatScrollerRef.current;
    if (!scroller) return;

    const isOverflowing = scroller.scrollHeight > scroller.clientHeight;

    setIsActive(isOverflowing);
    const handleScroll = () => {
      const maxScroll = scroller.scrollHeight - scroller.clientHeight;
      const currentScroll = scroller.scrollTop;
      const ratio = maxScroll ? currentScroll / maxScroll : 0;
      const scrollbarHeight = Math.max(
        20,
        scroller.clientHeight * (scroller.clientHeight / scroller.scrollHeight)
      ); // Ensure a minimum height for the scrollbar
      const translateYValue = ratio * (scroller.clientHeight - scrollbarHeight);

      setTranslateY(translateYValue); // Update scrollbar position
      setScrollbarHeight(scrollbarHeight); // Update scrollbar height
    };

    // Call handleScroll initially and on scroll events
    handleScroll();
    scroller.addEventListener("scroll", handleScroll);

    return () => scroller.removeEventListener("scroll", handleScroll);
  }, [chatHistory]); // Rerun when chatHistory changes to adjust for new content

  useEffect(() => {
    if (chatHistory.length > 0) {
      const lastIndex = chatHistory.length - 1; // Get the last index

      setTimeout(() => {
        const lastMessageElement = chatScrollerRef.current?.querySelector(
          `[data-index="${lastIndex}"]`
        );

        if (lastMessageElement) {
          // Calculate the position to scroll to, so the message is at the top of the chat view
          const scrollPosition =
            lastMessageElement.offsetTop - chatScrollerRef.current.offsetTop;
          chatScrollerRef.current.scrollTo({
            top: scrollPosition,
            behavior: "smooth",
          });
        }
      }, 100);
    }
  }, [chatHistory]);

  const renderServices = (services) => {
    // Placeholder functions for button actions

    return services?.map((service, index) => (
      <div key={index} className="chatbot-service-card">
        <div className="chatbot-service-image-and-title">
          {" "}
          <img
            src={service.images[0]}
            alt={service.title}
            className="chatbot-service-image"
          />{" "}
          <h3>{service.title}</h3>
        </div>

        <div className="chatbot-service-info">
          <span>
            {" "}
            <p>Price</p>
            <h3>${service.price || "Contact for price"}</h3>
          </span>
          <span>
            {" "}
            <p>Duration</p>
            <h3>{service.duration} mins</h3>
          </span>
        </div>
        <div className="chatbot-service-actions">
          <button className="chatbot-service-more">More Details</button>
          <button className="chatbot-service-book">Book Now</button>
        </div>
      </div>
    ));
  };

  const renderService = (serviceId) => {
    // Find the service by ID

    const service = servicesData.find((s) => s._id === serviceId);
    if (!service) {
      return <p>Service not found.</p>;
    }

    // Render the details of the found service
    return (
      <div className="chatbot-service-detail-card">
        <img
          src={service.images[0]}
          alt={service.title}
          className="chatbot-service-detail-image"
        />
        <div className="chatbot-service-detail-info">
          <h3>{service.title}</h3>

          <div>{parse(service.description)}</div>
          <h3>Price: ${service.price || "Contact for price"}</h3>
          <h3>Duration: {service.duration} minutes</h3>
        </div>
        <button className="chatbot-service-book">Book Now</button>
      </div>
    );
  };

  // Function to toggle chat box visibility
  const toggleChatBox = () => {
    setIsChatOpen(!isChatOpen);
  };

  const mainClassNames = `main is-connected ${
    isChatOpen
      ? "is-chat-opened is-fully-opened"
      : "is-chat-closed is-fully-closed"
  } is-widget is-side- is-style-round js-main`;

  const caretStyles = isChatOpen
    ? { height: "298px", transform: "translateY(96px) translateZ(0px)" }
    : { height: "40px", transform: "translateY(0px) translateZ(0px)" };

  // Function to handle message input change

  function sendResizeMessage() {
    const styles = {
      width: isChatOpen ? (isMobile ? "100%" : "555px") : "60px",
      height: isChatOpen ? (isMobile ? "100%" : "650px") : "60px",
      borderRadius: isChatOpen ? (isMobile ? "0" : "12px") : "50%",
      bottom: isChatOpen ? (isMobile ? "0px" : "20px") : "20px",
      right: isChatOpen ? (isMobile ? "0px" : "20px") : "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)", // Keeping shadow consistent as per your requirement
    };

    window.parent.postMessage(
      {
        action: "resize",
        styles: styles,
      },
      // "http://localhost:3000"
      // `http://${websiteData.domain}`,
    );
  }

  useEffect(() => {
    sendResizeMessage();
  }, [isChatOpen, isMobile]);

  useEffect(() => {
    function handleResizeMessage(event) {
      if (event.data.action === "screenWidth") {
        setIsMobile(event.data.isMobile);
      }
    }

    window.addEventListener("message", handleResizeMessage);

    return () => {
      window.removeEventListener("message", handleResizeMessage);
    };
  }, [isChatOpen]);

  const chatAndInputChatRef = useRef(null);

  useEffect(() => {
    const element = chatAndInputChatRef.current;

    if (element && gradientColors.length > 1) {
      // If gradientColors array has two colors, use the second one as the end color
      const endColor = gradientColors[1];
      element.style.setProperty("--user-gradient-end", endColor);
    }
  }, [gradientColors, chatHistory]);

  return (
    <>
      <div
        className={`chat-conversations sib-conversations chat-conversations--webkit chat-conversations--side-bottom chat-conversations--pos-right chat-conversations--style-round ${
          isChatOpen
            ? "chat-conversations--expanded"
            : "chat-conversations--visible"
        } chat-conversations--fast-toggle`}
        style={{
          transform: "none",
          zIndex: isChatOpen ? "2147483646" : "9999",
          width: determinePillWidth(isChatOpen, chatBubbleStyle),
          height: determinePillHeight(isChatOpen, chatBubbleStyle),
        }}
      >
        <div
          className="chat-conversations__wrapper"
          style={{
            borderRadius: determineBorderRadius(isChatOpen, chatBubbleStyle),
          }}
        >
          <div className="chat-conversations__iframe">
            <svg style={{ display: "none" }}>
              <defs>
                <symbol id="ei-arrow-down-icon" viewBox="8 8 34 34">
                  <path d="M25 42c-9.4 0-17-7.6-17-17S15.6 8 25 8s17 7.6 17 17-7.6 17-17 17zm0-32c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15-6.7-15-15-15z"></path>
                  <path d="M25 34.4l-9.7-9.7 1.4-1.4 8.3 8.3 8.3-8.3 1.4 1.4z"></path>
                  <path d="M24 16h2v17h-2z"></path>
                </symbol>

                <symbol id="ei-arrow-up-icon" viewBox="0 0 50 50">
                  <path d="M25 42c-9.4 0-17-7.6-17-17S15.6 8 25 8s17 7.6 17 17-7.6 17-17 17zm0-32c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15-6.7-15-15-15z"></path>
                  <path d="M33.3 26.7L25 18.4l-8.3 8.3-1.4-1.4 9.7-9.7 9.7 9.7z"></path>
                  <path d="M24 17h2v17h-2z"></path>
                </symbol>

                <symbol id="ei-paperclip-icon" viewBox="0 0 50 50">
                  <path d="M13.8 39.6c-1.5 0-3.1-.6-4.2-1.8-2.3-2.3-2.3-6.1 0-8.5l17-17c3.1-3.1 8.2-3.1 11.3 0 3.1 3.1 3.1 8.2 0 11.3L25.1 36.4 23.7 35l12.7-12.7c2.3-2.3 2.3-6.1 0-8.5-2.3-2.3-6.1-2.3-8.5 0l-17 17c-.8.8-1.2 1.8-1.2 2.8 0 1.1.4 2.1 1.2 2.8 1.6 1.6 4.1 1.6 5.7 0l12.7-12.7c.8-.8.8-2 0-2.8-.8-.8-2-.8-2.8 0L18 29.3l-1.4-1.4 8.5-8.5c1.6-1.6 4.1-1.6 5.7 0 1.6 1.6 1.6 4.1 0 5.7L18 37.8c-1.1 1.2-2.7 1.8-4.2 1.8z"></path>
                </symbol>

                <symbol id="ei-close-icon" viewBox="0 0 50 50">
                  <path d="M37.304 11.282l1.414 1.414-26.022 26.02-1.414-1.413z"></path>
                  <path d="M12.696 11.282l26.022 26.02-1.414 1.415-26.022-26.02z"></path>
                </symbol>

                <symbol id="ei-check-icon" viewBox="10 10 30 30">
                  <path d="M23 32.4l-8.7-8.7 1.4-1.4 7.3 7.3 11.3-11.3 1.4 1.4z"></path>
                </symbol>

                <symbol id="ei-exclamation-icon" viewBox="10 10 30 30">
                  <path d="M24 32h2v2h-2z"></path>
                  <path d="M25.6 30h-1.2l-.4-8v-6h2v6z"></path>
                </symbol>

                <symbol id="chat-icon-bubble" viewBox="0 0 512 512">
                  <path
                    d="M240,0 C372.5625,0 480,87.1407005 480,194.68599 C480,302.23128 372.5625,389.371981 240,389.371981 C204,389.371981 169.96875,382.726449 139.3125,371.213768 C87.109375,400.003959 42.421875,400.003959 14.0814012,400.003959 C11.0814012,400.003959 42.421875,361.363406 53.4375,317.019928 C20.0625,283.605072 -2.84217094e-14,241.111111 -2.84217094e-14,194.68599 C-2.84217094e-14,87.1407005 107.4375,0 240,0 Z M240,24 C119.710468,24 24,101.636307 24,194.68599 C24,232.868518 40.0525875,269.249177 69.5210975,299.155237 L70.4181865,300.059495 L79.9976491,309.650397 L76.7295758,322.806049 C72.6472992,339.239283 66.0642369,355.61453 57.4845168,372.224794 L56.8030315,373.536625 L56.644,373.838 L56.8007345,373.81731 C58.8315421,373.534605 60.8948043,373.217509 63.0261395,372.862271 L63.7391546,372.74244 C84.9526326,369.14813 105.630175,362.220208 126.579252,350.82399 L127.722203,350.19794 L137.401761,344.859633 L147.75012,348.745867 C176.71924,359.624962 207.933528,365.371981 240,365.371981 C360.289532,365.371981 456,287.735673 456,194.68599 C456,101.636307 360.289532,24 240,24 Z"
                    transform="translate(16 54)"
                  ></path>
                </symbol>

                <symbol id="chat-icon-bubbles" viewBox="0 0 576 512">
                  <path
                    opacity="0.9"
                    d="M208 352c-41 0-79.1-9.3-111.3-25-21.8 12.7-52.1 25-88.7 25a7.83 7.83 0 0 1-7.3-4.8 8 8 0 0 1 1.5-8.7c.3-.3 22.4-24.3 35.8-54.5-23.9-26.1-38-57.7-38-92C0 103.6 93.1 32 208 32s208 71.6 208 160-93.1 160-208 160z"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M576 320c0 34.3-14.1 66-38 92 13.4 30.3 35.5 54.2 35.8 54.5a8 8 0 0 1 1.5 8.7 7.88 7.88 0 0 1-7.3 4.8c-36.6 0-66.9-12.3-88.7-25-32.2 15.8-70.3 25-111.3 25-86.2 0-160.2-40.4-191.7-97.9A299.82 299.82 0 0 0 208 384c132.3 0 240-86.1 240-192a148.61 148.61 0 0 0-1.3-20.1C522.5 195.8 576 253.1 576 320z"
                  ></path>
                </symbol>

                <symbol
                  id="chat-icon-sendinblue"
                  fill="none"
                  viewBox="0 0 40 40"
                >
                  <circle fill="currentColor" cx="20" cy="20" r="20"></circle>
                  <path
                    fill="#fff"
                    d="M26.253 18.1743C27.4896 16.9636 28.0691 15.5642 28.0691 13.8621C28.0691 10.3455 25.481 8 21.5803 8H12V33H19.7254C25.5975 33 30 29.4073 30 24.6418C30 22.0317 28.6485 19.6878 26.253 18.1743ZM15.4767 11.252H21.1934C23.1244 11.252 24.3998 12.3487 24.3998 14.0127C24.3998 15.9033 22.7391 17.3407 19.3402 18.4374C17.0224 19.1553 15.98 19.7607 15.5932 20.4802L15.4767 20.4812V11.252ZM19.4162 29.748H15.4767V25.8907C15.4767 24.1886 16.9447 22.5246 18.9922 21.8811C20.8082 21.2758 22.3135 20.6704 23.5889 20.0285C25.2884 21.0127 26.3307 22.7132 26.3307 24.4913C26.3307 27.5166 23.3947 29.748 19.4162 29.748Z"
                  ></path>
                </symbol>

                <symbol id="ei-half-star-icon" viewBox="0 -2 55 55">
                  <g transform="translate(1, 1)">
                    <path d="M15.2 40.6c-.2 0-.4-.1-.6-.2-.4-.3-.5-.7-.4-1.1l3.9-12-10.2-7.5c-.4-.3-.5-.7-.4-1.1s.5-.7 1-.7h12.7L25 5.9c.1-.4.5-.7 1-.7s.8.3 1 .7L30.9 18h12.7c.4 0 .8.2 1 .6s0 .9-.4 1.1L34 27.1l3.9 12c.1.4 0 .9-.4 1.1s-.8.3-1.2 0L26 33l-10.2 7.4c-.2.1-.4.2-.6.2zM26 30.7c.2 0 .4.1.6.2l8.3 6.1-3.2-9.8c-.1-.4 0-.9.4-1.1l8.3-6.1H30.1c-.4 0-.8-.3-1-.7L26 9.5z"></path>
                  </g>
                </symbol>

                <svg id="icon-close" viewBox="0 0 22 22">
                  <path d="M14 11l8-8V2c0-1 0 0 0 0l-2-2h-1l-8 8-8-8H2C1 0 2 0 2 0L0 2v1l8 8-8 8v1c0 1 0 0 0 0l2 2h1l8-8 8 8h1l2-2v-1l-8-8z"></path>
                </svg>

                <symbol id="icon-smile" viewBox="0 0 50 50">
                  <path d="M25 8C15.6 8 8 15.6 8 25s7.6 17 17 17 17-7.6 17-17S34.4 8 25 8zm0 2c8.3 0 15 6.7 15 15s-6.7 15-15 15-15-6.7-15-15 6.7-15 15-15zm-6 8.7c-1 0-1.6.7-1.6 1.6 0 1 .7 1.6 1.6 1.6.8 0 1.5-1 1.5-2s-.7-1.6-1.5-1.6zm12 0c-.8 0-1.5.7-1.5 1.6 0 1 .7 1.6 1.5 1.6 1 0 1.6-1 1.6-2s-.7-1.6-1.6-1.6zM17.4 29v2.2S20.2 34 25 34c4.7 0 7.6-2.8 7.6-2.8v-2l-.4.3S29.6 32 25 32s-7.2-2.5-7.2-2.5l-.4-.4z"></path>
                </symbol>

                <symbol id="icon-send" viewBox="-9 -9 50 50">
                  <path d="M31.5 0.196q0.589 0.429 0.482 1.143l-4.571 27.429q-0.089 0.518-0.571 0.804-0.25 0.143-0.554 0.143-0.196 0-0.429-0.089l-8.089-3.304-4.321 5.268q-0.321 0.411-0.875 0.411-0.232 0-0.393-0.071-0.339-0.125-0.545-0.42t-0.205-0.652v-6.232l15.429-18.911-19.089 16.518-7.054-2.893q-0.661-0.25-0.714-0.982-0.036-0.714 0.571-1.054l29.714-17.143q0.268-0.161 0.571-0.161 0.357 0 0.643 0.196z"></path>
                </symbol>

                <symbol id="sendinblue-bot" viewBox="0 0 24 22">
                  <path
                    fill="#044A75"
                    d="M12 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM24 9a2 2 0 1 0-2 2v6a2 2 0 0 0 2-2V9ZM0 9c0-1.1.9-2 2-2v10a2 2 0 0 1-2-2V9Z"
                  ></path>
                  <path
                    fill="#0092FF"
                    d="M18 2H6a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4Z"
                  ></path>
                  <path
                    fill="none"
                    stroke="#044A75"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="M6 16s.8 2 6 2 6-2 6-2"
                  ></path>
                  <path
                    fill="#fff"
                    stroke="#044A75"
                    stroke-width="2"
                    d="M12 13a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                  ></path>
                </symbol>

                <symbol id="fa-check" viewBox="0 0 512 512">
                  <path d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"></path>
                </symbol>

                <symbol id="fa-arrow-right" viewBox="0 0 448 512">
                  <path d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path>
                </symbol>

                <symbol id="fa-thumbs-up" viewBox="0 0 512 512">
                  <path d="M496.656 285.683C506.583 272.809 512 256 512 235.468c-.001-37.674-32.073-72.571-72.727-72.571h-70.15c8.72-17.368 20.695-38.911 20.695-69.817C389.819 34.672 366.518 0 306.91 0c-29.995 0-41.126 37.918-46.829 67.228-3.407 17.511-6.626 34.052-16.525 43.951C219.986 134.75 184 192 162.382 203.625c-2.189.922-4.986 1.648-8.032 2.223C148.577 197.484 138.931 192 128 192H32c-17.673 0-32 14.327-32 32v256c0 17.673 14.327 32 32 32h96c17.673 0 32-14.327 32-32v-8.74c32.495 0 100.687 40.747 177.455 40.726 5.505.003 37.65.03 41.013 0 59.282.014 92.255-35.887 90.335-89.793 15.127-17.727 22.539-43.337 18.225-67.105 12.456-19.526 15.126-47.07 9.628-69.405zM32 480V224h96v256H32zm424.017-203.648C472 288 472 336 450.41 347.017c13.522 22.76 1.352 53.216-15.015 61.996 8.293 52.54-18.961 70.606-57.212 70.974-3.312.03-37.247 0-40.727 0-72.929 0-134.742-40.727-177.455-40.727V235.625c37.708 0 72.305-67.939 106.183-101.818 30.545-30.545 20.363-81.454 40.727-101.817 50.909 0 50.909 35.517 50.909 61.091 0 42.189-30.545 61.09-30.545 101.817h111.999c22.73 0 40.627 20.364 40.727 40.727.099 20.363-8.001 36.375-23.984 40.727zM104 432c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z"></path>
                </symbol>

                <symbol id="fa-thumbs-down" viewBox="0 0 512 512">
                  <path d="M496.656 226.317c5.498-22.336 2.828-49.88-9.627-69.405 4.314-23.768-3.099-49.377-18.225-67.105C470.724 35.902 437.75 0 378.468.014c-3.363-.03-35.508-.003-41.013 0C260.593-.007 195.917 40 160 40h-10.845c-5.64-4.975-13.042-8-21.155-8H32C14.327 32 0 46.327 0 64v256c0 17.673 14.327 32 32 32h96c17.673 0 32-14.327 32-32v-12.481c.85.266 1.653.549 2.382.856C184 320 219.986 377.25 243.556 400.82c9.9 9.9 13.118 26.44 16.525 43.951C265.784 474.082 276.915 512 306.91 512c59.608 0 82.909-34.672 82.909-93.08 0-30.906-11.975-52.449-20.695-69.817h70.15c40.654 0 72.726-34.896 72.727-72.571-.001-20.532-5.418-37.341-15.345-50.215zM128 320H32V64h96v256zm311.273-2.898H327.274c0 40.727 30.545 59.628 30.545 101.817 0 25.574 0 61.091-50.909 61.091-20.363-20.364-10.182-71.272-40.727-101.817-28.607-28.607-71.272-101.818-101.818-101.818H160V72.74h4.365c34.701 0 101.818-40.727 173.09-40.727 3.48 0 37.415-.03 40.727 0 38.251.368 65.505 18.434 57.212 70.974 16.367 8.78 28.538 39.235 15.015 61.996C472 176 472 224 456.017 235.648 472 240 480.1 256.012 480 276.375c-.1 20.364-17.997 40.727-40.727 40.727zM104 272c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z"></path>
                </symbol>

                <symbol id="fa-redo" viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M500 8h-27.711c-6.739 0-12.157 5.548-11.997 12.286l2.347 98.568C418.075 51.834 341.788 7.73 255.207 8.001 118.82 8.428 7.787 120.009 8 256.396 8.214 393.181 119.165 504 256 504c63.926 0 122.202-24.187 166.178-63.908 5.113-4.618 5.354-12.561.482-17.433l-19.738-19.738c-4.498-4.498-11.753-4.785-16.501-.552C351.787 433.246 306.105 452 256 452c-108.322 0-196-87.662-196-196 0-108.322 87.662-196 196-196 79.545 0 147.941 47.282 178.675 115.302l-126.389-3.009c-6.737-.16-12.286 5.257-12.286 11.997V212c0 6.627 5.373 12 12 12h192c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12z"
                  ></path>
                </symbol>
              </defs>
            </svg>
            <div id="main" className={mainClassNames}>
              <div className="container-chat">
                <div className="container__inner">
                  {(!showChatIntro || !isChatOpen) && (
                    <div className="container__header js-header">
                      <button
                        className="header header--online js-header"
                        style={{
                          background: `linear-gradient(to right, ${gradientColors[0]}, ${gradientColors[1]})`,
                        }}
                        title={
                          isChatOpen ? "Close" : "Chat with us, we are online!"
                        } // Change title based on isChatOpen
                        aria-label={
                          isChatOpen ? "Close" : "Chat with us, we are online!"
                        } // Change aria-label based on isChatOpen
                        // style={{ backgroundColor: "#000" }}
                        type="button"
                        onClick={toggleChatBox}
                      >
                        <div
                          className="header__inner js-header-inner"
                          style={{
                            color: "rgb(255,255,255)",
                            width: isChatOpen ? "" : "100%",
                          }}
                        >
                          <span className="header__welcome-text">
                            {cartItems.length === 0 && (
                              <p>
                                <b>Chat with us, we are online!</b>
                              </p>
                            )}

                            {cartItems.length > 0 && (
                              <p style={{ fontSize: "18px" }}>
                                Total amount to be paid:{" "}
                                <b>
                                  {" "}
                                  $
                                  {cartItems
                                    .reduce(
                                      (acc, item) =>
                                        acc + item.quantity * item.price,
                                      0
                                    )
                                    .toFixed(2)}
                                </b>
                              </p>
                            )}
                          </span>
                          {!isChatOpen && (
                            <>
                              {" "}
                              {chatBubbleStyle === "circular" ? (
                                <svg className="header__round-button-icon">
                                  <use href="#chat-icon-bubbles"></use>
                                </svg>
                              ) : (
                                <p className="header__round-button-icon-text">
                                  {textBoxContent}
                                  <FontAwesomeIcon
                                    icon={faChevronUp}
                                    style={{ marginLeft: "7px" }}
                                  />
                                </p>
                              )}
                            </>
                          )}

                          <div className="header__close">
                            <svg className="header__close-icon">
                              <use href="#icon-close"></use>
                            </svg>
                          </div>
                        </div>
                      </button>
                    </div>
                  )}

                  {showChatIntro ? (
                    <div
                      className="chat-intro-screen"
                      style={{
                        background: `linear-gradient(to top, ${introColor} 0%, white 70%)`,
                      }}
                    >
                      <div
                        className="chat-intro-header"
                        onClick={toggleChatBox}
                      >
                        <img src={logoUrl} style={{ width: logoSize }} />
                        <FontAwesomeIcon icon={faTimes} className="file-icon" />
                      </div>
                      {chatSales ? (
                        <>
                          <div className="chat-intro-message">
                            <h1>
                              <b>Hello there👋</b>
                            </h1>
                            <h1>
                              <b>How can we help?</b>
                            </h1>
                          </div>
                          <div className="chat-intro-quick-messages">
                            <div
                              className="chat-intro-quick-messages-containers"
                              onClick={() => {
                                setShowChatIntro(false);
                                setCurrentMessage(
                                  "How can this chatbot help my business?"
                                );
                                setTriggerUserMessage(true);
                              }}
                            >
                              <p>
                                <b>How can this chatbot help my business?</b>
                              </p>
                              <FontAwesomeIcon icon={faPaperPlane} />
                            </div>

                            <div
                              className="chat-intro-quick-messages-containers"
                              onClick={() => {
                                setShowChatIntro(false);
                              }}
                            ></div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="chat-intro-message">
                            <h1>
                              <b>Hello there 👋</b>
                            </h1>
                            <h1>
                              <b>How can we help?</b>
                            </h1>
                          </div>
                          <div className="chat-intro-quick-messages">
                            <div
                              className="chat-intro-quick-messages-containers"
                              onClick={() => {
                                setShowChatIntro(false);
                                setCurrentMessage(
                                  "What services do you offer?"
                                );
                                setTriggerUserMessage(true);
                              }}
                            >
                              <p>
                                <b>What services do you offer?</b>
                              </p>
                              <FontAwesomeIcon icon={faPaperPlane} />
                            </div>
                            <div
                              className="chat-intro-quick-messages-containers"
                              onClick={() => {
                                setShowChatIntro(false);
                                setCurrentMessage("Where are you located?");
                                setTriggerUserMessage(true);
                              }}
                            >
                              <p>
                                <b>Where are you located?</b>
                              </p>
                              <FontAwesomeIcon icon={faPaperPlane} />
                            </div>

                            <div
                              className="chat-intro-quick-messages-containers"
                              onClick={() => {
                                setShowChatIntro(false);
                                setCurrentMessage(
                                  "I need help with other things"
                                );
                                setTriggerUserMessage(true);
                              }}
                            >
                              <p>
                                <b>I need help with other things</b>
                              </p>
                              <FontAwesomeIcon icon={faPaperPlane} />
                            </div>
                          </div>
                        </>
                      )}
                      <video
                        className="userpic__video"
                        style={{
                          border: "none",
                          width: "50px",
                          height: "50px",
                        }}
                        onClick={() => {
                          setShowChatIntro(false);
                          setCurrentMessage("What is the purpose of all this?");
                          setTriggerUserMessage(true);
                        }}
                        autoPlay
                        muted
                        playsInline // This attribute is important for autoplay on iOS devices
                        src={require("../../assets/new-loader-boomerang-blue.mp4")}
                        onEnded={handleVideoEnd}
                      ></video>
                    </div>
                  ) : (
                    <div className="container__main-wrapper">
                      {/* <Confetti active={showConfetti} config={confettiConfig} /> */}
                      <div className="container__main">
                        <div className="chat-and-input">
                          <div
                            className="chat-and-input__chat js-chat"
                            ref={chatAndInputChatRef}
                          >
                            {chatHistory.length === 0 ? (
                              <div
                                className="chat-welcome custom-scrollbar"
                                style={{ overflow: "hidden" }}
                              >
                                <div
                                  className="chat-welcome__inner js-welcome-scroller custom-scrollbar__recipient"
                                  style={{
                                    overflowY: "scroll",
                                  }}
                                  ref={chatScrollerRef}
                                >
                                  <div className="chat-welcome__shaft">
                                    <div className="chat-faces chat-faces--1">
                                      <div className="chat-faces__face chat-faces__face--100">
                                        <div className="chat-faces__userpic">
                                          <div
                                            className="userpic is-loaded"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              lineHeight: "100px",
                                            }}
                                          >
                                            <div className="userpic__inner">
                                              <div className="userpic__text">
                                                Bricky
                                              </div>

                                              <img
                                                className="userpic__img js-userpic-img"
                                                src={userPic}
                                              />
                                            </div>
                                          </div>

                                          <div className="chat-faces__status"></div>
                                        </div>
                                        <div className="chat-faces__name">
                                          Bricky
                                        </div>
                                      </div>
                                    </div>

                                    <div className="chat-welcome-message parsed-text">
                                      Bricky is here to help!
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="custom-scrollbar__caret"
                                  style={caretStyles}
                                >
                                  <div className="custom-scrollbar__caret-inner"></div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="chat-wrapper js-chat-wrapper custom-scrollbar"
                                style={{ overflow: "hidden" }}
                              >
                                <div
                                  className="chat-scroller js-chat-scroller custom-scrollbar__recipient"
                                  style={{
                                    overflowY: "scroll",
                                    width: "calc(100%)",
                                    marginRight: "-18px",
                                  }}
                                  ref={chatScrollerRef}
                                >
                                  <div className="chat-shaft chat-shaft--online">
                                    <div
                                      className="chat-shaft__inner js-chat-shaft"
                                      ref={chatScrollerRef}
                                    >
                                      {chatHistory.map((msg, index) => (
                                        <div className="chatHistoryParent">
                                          <div
                                            className={`chat-message ${
                                              msg.role === "user"
                                                ? "is-user"
                                                : "is-assistant"
                                            }`}
                                            key={index} // Using index as key for simplicity, consider a unique identifier if available
                                            data-index={index} // Set data-index for DOM selection
                                          >
                                            <>
                                              {msg.role === "assistant" && (
                                                <div className="chat-message__userpic js-open-chat">
                                                  <div className="userpic is-loaded userpic--message">
                                                    <img
                                                      className="userpic__img js-userpic-img"
                                                      src={msg.userpic}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                              <div className="chat-message-possible-replies">
                                                {" "}
                                                <div className="chat-message__content">
                                                  <div className="chat-message__bubble-wrapper">
                                                    <div
                                                      className={`chat-message__bubble chat-bubble chat-bubble--${msg.role} js-message-bubble js-open-chat`}
                                                      style={
                                                        msg.role === "user"
                                                          ? {
                                                              background: `linear-gradient(to right, ${gradientColors[0]}, ${gradientColors[1]})`,
                                                            }
                                                          : {}
                                                      }
                                                    >
                                                      <div className="chat-bubble__inner">
                                                        <div
                                                          className="chat-bubble__message"
                                                          style={{
                                                            width:
                                                              msg.show ===
                                                                "showBookingCalendar" &&
                                                              !msg.questions
                                                                ? ""
                                                                : "",
                                                          }}
                                                        >
                                                          {/* <Confetti
                                                                          active={showConfetti}
                                                                          config={
                                                                            confettiConfig
                                                                          }
                                                                        /> */}
                                                          <span
                                                            className={`chat-bubble__message-text parsed-text parsed-text--message ${
                                                              msg.role ===
                                                              "user"
                                                                ? "parsed-text--dark-bg"
                                                                : "parsed-text--very-light-bg"
                                                            }`}
                                                          >
                                                            {msg.questions ? (
                                                              msg.questions[0]
                                                                .question
                                                            ) : (
                                                              <div>
                                                                {parse(
                                                                  msg.content
                                                                )}
                                                                {msg.attachments
                                                                  ?.length >
                                                                  0 && (
                                                                  <div className="chatbox-chat-attachments">
                                                                    {msg.attachments
                                                                      .slice(
                                                                        0,
                                                                        showAllAttachments
                                                                          ? msg
                                                                              .attachments
                                                                              .length
                                                                          : 6
                                                                      )
                                                                      .map(
                                                                        (
                                                                          attachment
                                                                        ) => (
                                                                          <div
                                                                            key={
                                                                              attachment._id
                                                                            }
                                                                            className="file-preview"
                                                                          >
                                                                            {isImage(
                                                                              attachment.name
                                                                            ) ? (
                                                                              <img
                                                                                src={
                                                                                  attachment.url
                                                                                }
                                                                                alt={
                                                                                  attachment.name
                                                                                }
                                                                                className="file-thumbnail"
                                                                              />
                                                                            ) : (
                                                                              <div className="file-icon-container">
                                                                                <FontAwesomeIcon
                                                                                  icon={getFileTypeIcon(
                                                                                    attachment.name
                                                                                  )}
                                                                                  className="file-icon"
                                                                                />
                                                                              </div>
                                                                            )}
                                                                            <div
                                                                              className="file-info-download-bar"
                                                                              onClick={() => {
                                                                                const link =
                                                                                  document.createElement(
                                                                                    "a"
                                                                                  );
                                                                                link.href =
                                                                                  attachment.url;
                                                                                link.target =
                                                                                  "_blank";
                                                                                link.click();
                                                                              }}
                                                                            >
                                                                              <p className="file-name">
                                                                                {attachment
                                                                                  .name
                                                                                  .length >
                                                                                5
                                                                                  ? `${attachment.name.substring(
                                                                                      0,
                                                                                      3
                                                                                    )}..${attachment.name
                                                                                      .split(
                                                                                        "."
                                                                                      )
                                                                                      .pop()}`
                                                                                  : attachment.name}
                                                                              </p>

                                                                              <p>
                                                                                {formatFileSize(
                                                                                  attachment.size
                                                                                )}
                                                                              </p>
                                                                              <FontAwesomeIcon
                                                                                icon={
                                                                                  faDownload
                                                                                }
                                                                                style={{
                                                                                  color:
                                                                                    "#045FE6",
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    {!showAllAttachments &&
                                                                      msg
                                                                        .attachments
                                                                        ?.length >
                                                                        6 && (
                                                                        <div className="attachment-preview">
                                                                          <div className="stacked-thumbnails">
                                                                            {msg.attachments
                                                                              .slice(
                                                                                6,
                                                                                9
                                                                              )
                                                                              .map(
                                                                                (
                                                                                  attachment,
                                                                                  index
                                                                                ) => (
                                                                                  <div
                                                                                    key={
                                                                                      index
                                                                                    }
                                                                                    className={`file-thumbnail-preview preview-${index}`}
                                                                                  >
                                                                                    {isImage(
                                                                                      attachment.name
                                                                                    ) && (
                                                                                      <img
                                                                                        src={
                                                                                          attachment.url
                                                                                        }
                                                                                        alt={
                                                                                          attachment.name
                                                                                        }
                                                                                        className="preview-image"
                                                                                      />
                                                                                    )}
                                                                                    {!isImage(
                                                                                      attachment.name
                                                                                    ) && (
                                                                                      <FontAwesomeIcon
                                                                                        icon={getFileTypeIcon(
                                                                                          attachment.name
                                                                                        )}
                                                                                        className="preview-icon"
                                                                                      />
                                                                                    )}
                                                                                  </div>
                                                                                )
                                                                              )}
                                                                          </div>
                                                                          <div className="overlay-stacked-files-chat">
                                                                            +
                                                                            {msg
                                                                              .attachments
                                                                              .length -
                                                                              6}{" "}
                                                                            more
                                                                          </div>
                                                                        </div>
                                                                      )}

                                                                    {msg
                                                                      .attachments
                                                                      ?.length >
                                                                      6 && (
                                                                      <span
                                                                        onClick={() =>
                                                                          setShowAllAttachments(
                                                                            !showAllAttachments
                                                                          )
                                                                        }
                                                                        className="show-more-attachments-text"
                                                                      >
                                                                        {showAllAttachments ? (
                                                                          <span>
                                                                            <p>
                                                                              Show
                                                                              Less
                                                                            </p>
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faChevronUp
                                                                              }
                                                                            />
                                                                          </span>
                                                                        ) : (
                                                                          <span>
                                                                            <p>
                                                                              Show
                                                                              More
                                                                            </p>
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faChevronDown
                                                                              }
                                                                            />
                                                                          </span>
                                                                        )}
                                                                      </span>
                                                                    )}
                                                                  </div>
                                                                )}
                                                              </div>
                                                            )}
                                                            {msg.show ===
                                                              "showAllServices" &&
                                                              renderServices(
                                                                servicesData
                                                              )}
                                                            {msg.show ===
                                                              "showService" &&
                                                              renderService(
                                                                msg.serviceId
                                                              )}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {msg.possibleReplies && (
                                                  <div className="chat-message__replies">
                                                    {msg.possibleReplies.map(
                                                      (reply, replyIndex) => (
                                                        <button
                                                          key={replyIndex}
                                                          className="reply-pill"
                                                        >
                                                          {reply}
                                                        </button>
                                                      )
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </>
                                          </div>
                                        </div>
                                      ))}

                                      {/* Add any other elements like the chat typing indicator here */}
                                      {isBotTyping && (
                                        <div className="chat-message is-assistant">
                                          <div className="chat-message__userpic">
                                            <div className="userpic is-loaded userpic--message">
                                              <video
                                                className="userpic__video"
                                                autoPlay
                                                muted
                                                playsInline // This attribute is important for autoplay on iOS devices
                                                src={require("../../assets/new-loader-boomerang-blue.mp4")}
                                                onEnded={handleVideoEnd}
                                              ></video>
                                            </div>
                                          </div>
                                          <div className="chat-message__content">
                                            <div className="chat-bubble--typing">
                                              <div className="chat-bubble__inner">
                                                <div className="chat-bubble__message">
                                                  <div className="typing">
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                    <span className="dot"></span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`custom-scrollbar__caret ${
                                    isActive ? "is-active" : ""
                                  }`}
                                  style={{
                                    height: `${scrollbarHeight}px`,
                                    transform: `translateY(${translateY}px) translateZ(0px)`,
                                  }}
                                >
                                  <div className="custom-scrollbar__caret-inner"></div>
                                </div>
                              </div>
                            )}
                          </div>
                          <ChatBoxInput
                            handleMessageChange={handleMessageChange}
                            handleUserMessage={handleUserMessage}
                            currentMessage={currentMessage}
                            setCurrentMessage={setCurrentMessage}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteWideChatBox;
