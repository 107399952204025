import React, { useState, useEffect, useRef } from "react";
import "./ChatBoxInput.css";
import { ToastContainer, toast } from "react-toastify";
import Picker from "emoji-picker-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {
  faChevronDown,
  faCalendarCheck,
  faClock,
  faPaperPlane,
  faFilePdf,
  faQuestionCircle,
  faChevronUp,
  faPaperclip,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFile,
  faDownload,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const fileQueue = [];

const ChatBoxInput = ({
  handleMessageChange,
  handleUserMessage,
  currentMessage,
  setCurrentMessage,

}) => {
  const [fileUploadStatus, setFileUploadStatus] = useState({});
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const emojiPickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    // Add when the emoji picker is shown
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);






  const UploadIcon = () => (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.27 9.512l5.882-5.882a1.685 1.685 0 012.383 2.384l-5.928 5.929A3.314 3.314 0 012.92 7.257l4.149-4.15"
        stroke="#757575"
        stroke-width="1.3"
        stroke-miterlimit="10"
        stroke-linecap="round"
      ></path>
    </svg>
  );

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };




  return (
    <div className="chat-input">
      <div className="chat-input-main-elements">
        <input
          type="file"
          id="file-upload"
          style={{ display: "none" }}
         
          multiple
        />
        <label htmlFor="file-upload" className="upload-button">
          <UploadIcon />
        </label>
        <button className="emoji-toggle-button" onClick={toggleEmojiPicker}>
          😊
        </button>
        {showEmojiPicker && (
          <div
            className="emoji-picker-react"
            ref={emojiPickerRef}
            style={{ position: "relative" }}
          >
            {/* Close button */}
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => setShowEmojiPicker(false)}
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                cursor: "pointer",
                border: "none",
                background: "transparent",
                fontSize: "22px",
                padding: "6px",
                zIndex: "100",
                color: "#D90000",
              }}
            />

            <Picker
              onEmojiClick={(emojiObject) =>
                setCurrentMessage((prevMsg) => prevMsg + emojiObject.emoji)
              }
            />
          </div>
        )}

        <input
          type="text"
          value={currentMessage}
          onChange={handleMessageChange}
    
          placeholder="Type a message..."
          className="message-input"
          disabled={isUploading}
        />
        <button
          id="sendButtonBricksoft"
          className="send-button"
      
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
      <div className="chat-input-main-elements-mobile">
        <div>
          {" "}
          <input
            type="text"
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            placeholder="Type a message..."
            className="message-input"
            disabled={isUploading}
          />
        </div>

      </div>

    </div>
  );
};

export default ChatBoxInput;
